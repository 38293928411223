import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Grid } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "gatsby-plugin-react-i18next";
import qrcode from "./qrcode.png";
import location from "../../data/images/location.jpg";

const useStyles = makeStyles((theme) => ({
  map: {
    boxShadow:
      "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
    filter: "drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08))",
    borderRadius: "10px",
    border: 0,
    width: "100%",
    height: 300,
    [theme.breakpoints.up("md")]: {
      width: 500,
      height: 310,
    },
  },
  divider: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("lg")]: {
      width: "90%",
    },
  },
  contactonWhatsapp: {
    marginBottom: "4px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
  },
  qrcode: {
    width: "100%",
    minHeight: "200px",
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
  },
  icon: {
    background: "transparent",
    borderRadius: 0,
  },
  contactIcon: {
    color: theme.palette.secondary.main,
    marginRight: "3%",
    marginBottom: "6%",
  },
}));

const Information = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item xs={12} md={7}>
          <a
            href="https://maps.app.goo.gl/dGLhX9mXqkMfdyhe8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={location}
              alt="The Center Space Location"
              className={classes.map}
            />
          </a>
        </Grid>
        <Grid item xs={12} md={5}>
          <div>
            <Grid container style={{ marginBottom: "26px" }}>
              <Grid item xs={1}>
                <i className={clsx("fas fa-phone-alt", classes.contactIcon)} />
              </Grid>
              <Grid item xs={11}>
                <Typography align="left" variant="body1">
                  {t("contactPhone")}: (852) 2163 7688
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: "26px" }}>
              <Grid item xs={1}>
                <i className={clsx("fas fa-envelope", classes.contactIcon)} />
              </Grid>
              <Grid item xs={11}>
                <Typography align="left" variant="body1">
                  {t("contactEmail")}: growth@thecenterspace.co
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                align="center"
                variant="body1"
                className={classes.contactonWhatsapp}
              >
                {t("contactWhatsapp")}
              </Typography>
            </Grid>
            <Grid
              style={{
                textAlign: "center",
              }}
            >
              <div className={classes.qrcode}>
                <img src={qrcode} alt="qr code" width="160px" height="160px" />
              </div>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container>
              <Grid item xs={1}>
                <i className={clsx("fa fa-map-marker", classes.contactIcon)} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  align="left"
                  variant="h6"
                  style={{ marginTop: "5%", marginBottom: "20px" }}
                >
                  {t("contactVisitUs")}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: "26px" }}>
              <Grid item xs={1}>
                <i className={clsx("fa fa-map-marker", classes.contactIcon)} />
              </Grid>
              <Grid item xs={11}>
                <Typography align="left" variant="body1">
                  {t("contactAddress")
                    .split("\n")
                    .map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Information;
